import React from "react";
import { Box } from "@mui/material";
import { colors } from "../utils/const";
import { styles } from "../sections/home/image_section";
import Link from "next/link";
import { useRouter } from "next/router";
import en from "../../locales/en";
import ar from "../../locales/ar";

export default function ImageCard({
  img,
  title1,
  title2,
  url,
  delay,
  alt,
  bgcolor,
  optionId,
}) {
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;

  const isSmall = optionId == null ? true : false;
  const width = isSmall
    ? { xs: "150px", sm: "220px", md: "224px" }
    : { xs: "150px", sm: "220px", md: "282px" };
  const height = isSmall
    ? { xs: "150px", sm: "220px", md: "224px" }
    : { xs: "150px", sm: "220px", md: "282px" };

  return (
    <Box
      component={Link}
      href={url}
      data-aos="fade-up"
      sx={{
        width,
        textDecoration: "none",
      }}
    >
      <Box
        sx={{
          "&:hover .img-pick": {
            backgroundSize: "110%",
            color: colors.white,
          },
        }}
      >
        <Box
          className="img-pick"
          role="img"
          aria-label={alt}
          loading="lazy"
          sx={{
            width,
            height: height,
            color: colors.white,
            background: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.0)),url(${img})`,
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: bgcolor,
            borderRadius: "12px 12px 0px 0px",
            textAlign: t.textAlign,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            transition: "1s",
            transitionTimingFunction: "ease",
            textDecoration: "none",
            position: "relative",
          }}
        ></Box>
        <Box
          sx={{
            textAlign: "center",
            p: 2,
            fontWeight: "bold",
            fontSize: { xs: "11px", md: "16px" },
            color: "#404456",
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: "0px 0px 12px 12px",
          }}
        >
          {title2}
        </Box>
      </Box>

      {img === "/pages/green-watering-small.png" && (
        <Box sx={{ display: "flex", flexDirection: "column", mt: "50px" }}>
          <Box
            component="button"
            sx={{
              padding: "8px 16px 8px 16px",
              borderRadius: "8px",
              color: "#fff",
              backgroundColor: "#F18222",
              border: "1px solid #F18222",
              alignSelf: "flex-end",
            }}
            onClick={(e) => {
              router.push("/products/green-finance");
            }}
          >
            {t.other_products}
          </Box>
        </Box>
      )}
    </Box>
  );
}
