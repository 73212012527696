import React, { useState } from "react";
import { Box } from "@mui/material";
import { styles } from "./image_section";
import ImageCard from "../../components/ImageCard.jsx";
import { useRouter } from "next/router";
import en from "../../../locales/en";
import ar from "../../../locales/ar";
import Title from "../../components/mini-components/Title.jsx";
export default function ImageSection({}) {
  const [optionId, setOptionId] = useState("1");
  const gridOptions =
    optionId == null
      ? {
          xs: "auto auto",
          sm: "repeat(auto-fill, 224px)",
        }
      : {
          xs: "auto auto",
          sm: "repeat(auto-fill, 300px)",
        };
  const router = useRouter();
  const { locale } = router;
  const t = locale === "en" ? en : ar;
  return (
    <Box
      id="all-products"
      sx={styles.container}
      className="horizontal-safe-padding vertical-safe-padding"
    >
      <Title title={t.home_page.products_title} />

      <Box sx={styles.btnContainer}>
        {t.home_page.products_options.map((item, index) => {
          return (
            <Box
              key={index}
              component="button"
              sx={item.id === optionId ? styles.btnActive : styles.btn}
              onClick={() => setOptionId(item.id)}
            >
              <span>{item.title}</span>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          ...styles.grid,
          gridTemplateColumns: gridOptions,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: {
            xs: "center",
            sm: "start",
          },
        }}
      >
        {t.home_page.products
          .filter((item) => item.optionId === optionId)
          .map((item, index) => {
            return (
              <ImageCard
                key={Math.random()}
                title1={item.title1}
                title2={item.title2}
                img={item.small_img}
                url={item.url}
                alt={item.alt}
                bgcolor={item.bgcolor}
                optionId={item.optionId}
              />
            );
          })}
      </Box>
    </Box>
  );
}
