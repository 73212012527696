export const styles = {
  container: (theme) => ({
    bgcolor: "#F8F8F8",
  }),
  grid: {
    display: "grid",
    gridGap: 16,
    pt: 2,
    pb: 2,
  },
  btnContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "10px",
    mt: "10px",
    mb: "10px",
  },
  btn: {
    textAlign: "center",
    borderRadius: "8px",
    padding: "8px",
    fontSize: { xs: "14px", sm: "16px" },
    width: { Xs: "initial", sm: "initial", md: "160px", lg: "160px" },
    height: { Xs: "initial", sm: "30px", md: "48px" },
    backgroundColor: "#F1F1F1",
    border: "1px solid #F1F1F1",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  btnActive: {
    textAlign: "center",
    borderRadius: "8px",
    padding: "8px",
    fontSize: { xs: "14px", sm: "16px" },
    width: { Xs: "initial", sm: "initial", md: "160px" },
    height: { Xs: "initial", sm: "30px", md: "48px" },
    color: "#F18222",
    backgroundColor: "#F182220D",
    border: "1px solid #F18222",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "#F182220D",
      zIndex: 0,
      pointerEvents: "none",
      opacity: 1,
    },

    "& span": {
      position: "relative",
      zIndex: 1,
    },
  },
};
